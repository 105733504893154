@use "sass:map";
@use '@angular/material' as mat;

// Mat Chip
.mat-mdc-chip {
  &.small {
    font-size: 11px !important;

    &.icon-chip .mat-icon {
      $size: 16px;
      height: $size;
      width: $size;
      font-size: $size;
      line-height: 1.5;
    }
  }
}
// FIX for long chip text wrapped and messed
// Ticket still open: https://github.com/angular/components/issues/26584
.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}

// Mat Icon
.mat-icon {
  &.green {
    color: map.get(mat.$m2-green-palette, 500) !important;
  }

  &.red {
    color: map.get(mat.$m2-red-palette, 500) !important;
  }

  &.yellow {
    color: map.get(mat.$m2-amber-palette, 500) !important;
  }

  &.flashing {
    animation: blinker 2s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }

  &.grey {
    color: map.get(mat.$m2-gray-palette, 500) !important;
  }

  // this is used for chips where you can remove items,
  // like holidays in facility timings or most custom selector inputs
  &.pointer.mat-icon-close {
    transform: scale(0.5);
    opacity: 0.5;
    transition: 0.1s all ease-in-out;
  }
}

// Mat Input
input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block; //https://material.angular.io/guide/mdc-migration#input
}

// Mat List
.mat-mdc-list-base .mat-mdc-list-item,
.mat-mdc-menu-item,
.mat-mdc-option,
mat-select-trigger {
  font-size: 0.875rem !important;
}

.mat-mdc-list-item {
  &.selected {
    background-color: var(
        --selected-background
    ) !important; // important to override :hover and :active
  }
}

.mat-mdc-selection-list:focus {
  outline: none; // overrides browser default -webkit-focus-ring-color auto 1px;
}

// Mat Select
.mat-mdc-select-panel {
  &.workflow-info-large-panel {
    max-height: 400px;

    @media (min-height: 930px) {
      max-height: 500px;
    }
  }

  &.question-dialog {
    max-height: 500px;
  }
}

// Mat Tooltip
.mat-mdc-tooltip {
  white-space: pre-line;
}
