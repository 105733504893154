@use "sass:map";
@use '@angular/material' as mat;
@use 'mixins/ppal-theming' as *;

.action-bar-container {
  position: fixed;
  bottom: 24px;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  animation: fadein 0.5s;

  .action-bar {
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 344px;
    padding: 14px 16px;
    min-height: 48px;
    transform-origin: center;
    display: flex;
    justify-items: flex-end;
    align-items: center;

    span {
      white-space: nowrap;
    }

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 24px;
      opacity: 1;
    }
  }
}

@include apply-console-themes(false) {
  .action-bar-container .action-bar {
    $color: map.get($theme, color);
    $is-dark-theme: map.get($color, is-dark);
    $accent: map.get($color, accent);
    // todo find out how to apply the styles without accessing to the inner variables
    color: if($is-dark-theme, #fff, #000);
    background: if($is-dark-theme, map.get(mat.$m2-gray-palette, 50), #323232);
    @include mat.private-theme-elevation(6, $color);
  }
}
