@use '../variables' as *;

* {
  text-rendering: optimizeLegibility;
}

html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;

  &:not(.scrollable) {
    overflow: hidden;
  }
}

body {
  margin: 0;
  padding: 0;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: $font-family-base;
  -moz-osx-font-smoothing: auto;

  --header-height: 64px;
  --patient-header-height: calc(var(--header-height) - 16px);
  --card-padding: 0.75rem;
  --card-margin: 0.3333rem;
}

[tabindex='-1']:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}
div {
  box-sizing: border-box;
}
html,
body {
  &[dir='rtl'],
  &[dir='ltr'] {
    unicode-bidi: embed;
  }
}
bdo[dir='rtl'] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
bdo[dir='ltr'] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
img:not(.mat-mdc-card-image) {
  max-width: 100%;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
a {
  color: inherit;
}
p {
  margin: 0 0 16px;
}
code {
  background: rgba(0, 0, 0, 0.08);
}
/*---- Common -----*/
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.font-normal {
  font-weight: normal !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

.list-item-active {
  border-left: 3px solid;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.ql-container .ql-editor {
  min-height: 200px;
}
.ql-tooltip {
  left: 0 !important;
}
.chart {
  display: block;
  width: 100%;
}

/*---- Third party adjust -----*/

/*-------- Chart js ---------*/
.chart {
  margin-left: -10px;
}

.text-primary {
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.25;
  border-color: var(--divider);
  color: var(--primary-text-color);

  &.uppercase {
    font-weight: 700;
    letter-spacing: 0.05rem;
  }
}
.text-secondary {
  color: var(--primary-text-color) !important;
}
.text-accent {
  color: var(--accent-text-color) !important;
}
.text-accent-darker {
  color: var(--accent-text-darker-color) !important;
}
.text-muted {
  color: var(--hint-text-color) !important;
}
.text-warn {
  color: var(--warn-text-color) !important;
}
.text-light {
  font-weight: 200 !important;
}
.text-normal {
  font-weight: 400 !important;
}
.text-bold {
  font-weight: 700 !important;
}
.text-bolder {
  font-weight: 900 !important;
}
.line-through {
  text-decoration: line-through;
}
.text-fixed {
  white-space: pre;
}
.text-link {
  cursor: pointer;
  border-bottom: 1px dashed var(--primary-text-color);

  &:active,
  &:focus,
  &:hover {
    border-bottom: 1px solid var(--primary-text-darker-color);
  }
}

.label-cell {
  font-weight: bold;
}

.info-cell {
  border-bottom: 1px dashed;
  min-height: 20px;
  cursor: pointer;
}

.errorCls {
  color: var(--warn-text-color);
  padding: 10px 0;
}

.successCls {
  color: var(--primary-text-color);
  padding: 10px 0;
}

.word-break {
  word-break: break-all;
}

.header-topnav .topbar-branding {
  position: relative;
  top: -5px;
}

/* ngx-mat-intl-tel-input */
.country-list-button {
  color: var(--text-color) !important;
}
.country-selector {
  color: var(--text-color) !important;
  margin-top: 15px !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-color);
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.cdk-overlay-container {
  z-index: 999 !important;
}

address {
  font-style: normal;
}

.pointer {
  cursor: pointer;
}

img.options-img {
  max-height: 500px;
  width: 20%;
}

input#input-chip-list {
  margin: 0;
  padding: 4px;
}

@media screen and (max-width: 599px) {
  body {
    --header-height: 56px;
  }
}
