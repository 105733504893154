.header-topnav {
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    left: auto;
    right: 6px;
  }

  .mat-badge-medium.mat-badge-above .mat-badge-content {
    top: 6px;
  }
}
