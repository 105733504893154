@use '@angular/material' as mat;

:root {
  @include mat.form-field-overrides((
    filled-active-indicator-color: rgba(0, 0, 0, .42),
    filled-hover-active-indicator-color: rgba(0, 0, 0, .42),
  ));
}

.mat-mdc-form-field-hint {
  color: #1a202e99;
}
