@use 'sass:math';

@use '../variables' as *;

.dialog-form {
  width: 600px;
  min-height: 400px;
  $padding: 24px;

  .mat-mdc-dialog-container {
    // it feels like it was a legit way to override appearance in older versions:
    // https://v5.material.angular.io/guide/customizing-component-styles
    // but seems there is a more proper way to do it:
    // https://v14.material.angular.io/cdk/dialog/overview#providing-a-custom-dialog-container
    // however, it fails for some reason with the runtime errors
    // todo needs deeper investigation
    padding: 0;
    min-height: 100px;

    .mat-mdc-progress-bar {
      height: 2px;
      margin-top: -2px;
    }

    .mat-mdc-dialog-title {
      min-height: 36px;
      box-sizing: border-box;
      padding: 0.5rem 1rem;
      line-height: 40px;
      font-weight: 500;
      background: var(--dialog-background);
      margin: 0;
      border-bottom: $card-border-width solid var(--divider);

      .mat-mdc-icon-button {
        line-height: 36px;
      }

      h2,
      > span {
        margin: 0;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        font-size: 0.8125rem;
        font-weight: 700;
        color: var(--primary-text-color);
      }

      h2 {
        padding: 0.6rem 0.2rem;
      }

      h3 {
        margin: 0;
        font-size: 16px;
      }

      &::before {
        display: none;
      }
    }

    .mat-mdc-dialog-content {
      padding: ($padding * 0.5) $padding;
      margin: 0;
      max-height: 75vh;
      &.large-content {
        max-height: 78vh;
      }
    }

    .separator {
      box-sizing: border-box;
      padding: math.div($padding, 3) $padding;
      margin-top: 0;
      margin-left: (-$padding);
      margin-right: (-$padding) !important;
      margin-bottom: 8px;
      max-width: none !important;
    }

    .checkbox-form-field {
      .mat-mdc-form-field-hint {
        display: block;
        color: rgba(0, 0, 0, 0.54);
        font-size: 75%;
      }
    }
  }

  .mat-mdc-dialog-actions {
    border-top: $card-border-width solid var(--divider);
    background-color: var(--status-bar-background);
    padding: 1.25rem 2rem;
    margin: 0;
  }
}

.spotlight-search {
  .mat-mdc-dialog-container {
    background: transparent !important;
    box-shadow: none !important;

    // new layout in Material v15 needs additional overrides
    .mdc-dialog__surface {
      position: static;
    }
  }
}
