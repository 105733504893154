@use '../variables' as *;

.sidebar-panel {
  min-height: 100vh;
  width: $sidebar-width;
  -webkit-transition: all $sidebarTrnTime $transitionTiming;
  transition: all $sidebarTrnTime $transitionTiming;

  .navigation-hold {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    -webkit-transition: all $sidebarTrnTime $transitionTiming;
    transition: all $sidebarTrnTime $transitionTiming;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.main-content-wrap {
  position: relative;
  height: 100vh;
  transition: width $sidebarTrnTime $transitionTiming;
  overflow: hidden !important;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all $sidebarTrnTime $transitionTiming;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.layout-intransition {
  .branding {
    display: none !important;
  }

  .app-user-controls,
  .app-user-name {
    opacity: 0 !important;
  }
}

.sidebar-compact,
.sidebar-compact-big {
  .app-user,
  .app-logo-text {
    display: none;
  }
}
